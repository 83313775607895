// This React component is a LoginForm that creates a modal login form.
// The component imports the necessary CSS styles and images for the form, including logos for third-party authentication options.
// The form is rendered conditionally based on the isOpen prop, and a closeButton is provided to close the form.
// The login form includes email and password fields, a remember me checkbox, a forgot password link, and buttons for third-party authentication options.

// Import required dependencies
import React, { useState } from "react";
import "./login.css";
import logoPath from "./logo.png";
import imagePath from "./image.png";
import googleLogo from "./third-parties/google.png";
import yahooLogo from "./third-parties/yahoo.png";
import microsoftLogo from "./third-parties/microsoft.png";
import tipLogo from "./mark.png";

// import { auth } from "../../firebaseConfig"; 

const LoginForm = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    alert("The system is currently under maintenance. Please try again later.");
    // e.preventDefault();
    // try {
    //   await auth.signInWithEmailAndPassword(email, password);
    //   onClose();
    // } catch (error) {
    //   console.error("Error signing in", error);
    //   alert(error.message);
    // }
  };

  if (!isOpen) return null;

  return (
    // Create a loginForm container that is fixed and centered on the screen
    <div className="loginForm">
      <div className="modalContainer">
        <button className="closeButton" onClick={onClose}>
          X
        </button>

        <div className="leftSection">
          <img src={logoPath} alt="Logo" className="logo2" />
          <img src={imagePath} alt="Illustration" className="illustration" />
        </div>

        <div className="rightSection">
          <div className="formContainer">
            <h2>Sign In</h2>
            <p>
              If you don’t have an account register, you can{" "}
              <span className="registerLink" onClick={handleSubmit}>Register Here!</span>
            </p>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="abc@abc.com"
              className="emailInput"
            />
            <label htmlFor="password">
              Password
              <span className="passwordTooltip">
                <img src={tipLogo} alt="Info" />
              </span>
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="passwordInput"
            />
            <div className="bottomSection">
              <label className="rememberMe">
                <input type="checkbox" />
                Remember me
              </label>
              <span className="forgotPassword">Forget Password?</span>
            </div>
            <button className="loginButton" onClick={handleSubmit}>Login</button>
            <div className="separator">
              <hr />
              <span>or</span>
              <hr />
            </div>
            <div className="thirdPartyLogos">
              <img src={googleLogo} alt="Google" />
              <img src={yahooLogo} alt="Yahoo" />
              <img src={microsoftLogo} alt="Microsoft" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
